<template>
  <section>
    <two-panel
      :leftPanelWidth="'1050'"
      :rightPanelWidth="'337'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <!---------------------------- 예측모델 목록 ---------------------------->
        <table-list-component
          :tableHeight="'603'"
          ref="tableListComponent"
          :headers="headers"
          :items="itemsWithCustom"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :reloadToggle="reloadToggle"
          :loading="loading"
          @rowClick="rowClick"
          @getDataFromApi="searchPrdcList"
        >
          <!---- 검색 옵션 ---->
          <template #searchOptions>
            <label class="sort-label"
              >· {{ $t('support.prediction.search-condition1') }}</label
            >
            <input
              v-model="searchOpts.predictModlId"
              type="number"
              class="sort-input-text"
              maxlength="20"
              @keyup.enter="keyUpEnter"
              @keydown="inputNumberCheck"
            />
            <label class="sort-label"
              >· {{ $t('support.prediction.search-condition2') }}</label
            >
            <input
              v-model="searchOpts.predictModlNm"
              type="text"
              class="sort-input-text"
              maxlength="30"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
          </template>
          <!---- subHeaderItem ---->
          <template #subHeaderItem>
            <input
              type="button"
              :value="btnRegister"
              class="bt-line24 cr-orange"
              @click="openPopup('insert')"
            />
          </template>
        </table-list-component>
      </template>
      <!---- 상세 정보 detailInfo ---->
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <!-- custom slot-->
          <template v-slot:predictModlDc_tab_custom="{ item }">
            <td>
              <div style="height:60px; overflow-y:auto;">
                {{ item.value || '-' }}
              </div>
            </td>
          </template>
          <template v-slot:inputVriabl_tab_custom="{ item }">
            <td>
              <div style="height:60px; overflow-y:auto;">
                {{ item.value || '-' }}
              </div>
            </td>
          </template>
          <template v-slot:outptVriabl_tab_custom="{ item }">
            <td>
              <div style="height:60px; overflow-y:auto;">
                {{ item.value || '-' }}
              </div>
            </td>
          </template>
          <template v-slot:fileNm_tab_custom="{ item }">
            <td>
              <a v-if="item.value" class="a_file" @click="fileDownload">
                {{ item.value }}
              </a>
              <span v-else>-</span>
            </td>
          </template>
          <!-- bottom btn -->
          <template #detail_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  @click="openPopup('update')"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray ml-1"
                  @click="deleteModel"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>
    <!---------------------------- 예측모델 팝업 ---------------------------->
    <div class="pop-drag" v-if="popup.show">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:500px">
        <div class="pop-head">
          <h2>{{ popup.title }}</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <validation-observer ref="observer">
            <form enctype="multipart/form-data">
              <table class="table-style-default table-pop">
                <colgroup>
                  <col width="115px" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr v-if="popup.type == 'update'">
                    <th>{{ $t('support.prediction.popUp.label9') }}</th>
                    <td>
                      <input
                        type="text"
                        class="input-24"
                        name="u_predictModlId"
                        style="width:200px"
                        readonly="readonly"
                        :value="selected_predictModlId"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ modelName }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        :label="modelName"
                        v-model="popup.submitItem.predictModlNm"
                        name="predictModlNm"
                        :rules="{
                          required: true,
                          max: 50,
                        }"
                        maxlength="100"
                        inputClass="input-24"
                        inputStyle="width:200px"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ modelType }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        :label="modelType"
                        v-model="popup.submitItem.predictModlTy"
                        name="predictModlTy"
                        :rules="{
                          required: true,
                          max: 20,
                        }"
                        maxlength="100"
                        inputClass="input-24"
                        inputStyle="width:200px"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ modelVer }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="number"
                        :label="modelVer"
                        v-model="popup.submitItem.predictModlVer"
                        name="predictModlVer"
                        :rules="{
                          required: true,
                          decimal: true,
                          max: 11,
                        }"
                        inputClass="input-24"
                        inputStyle="width:200px"
                        maxlength="100"
                        @keydown="inputFloatCheck"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ modelDesc }}</th>
                    <td>
                      <validate-form
                        type="textarea"
                        :label="modelDesc"
                        v-model="popup.submitItem.predictModlDc"
                        name="predictModlDc"
                        :rules="{ max: 150 }"
                        inputClass="input-24 w-100"
                        inputStyle="height:60px; overflow-y:auto;"
                        maxlength="100"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ inputVar }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="textarea"
                        :label="inputVar"
                        v-model="popup.submitItem.inputVriabl"
                        name="inputVriabl"
                        :rules="{ required: true, max: 32000 }"
                        inputClass="input-24 w-100"
                        inputStyle="height:60px; overflow-y:auto;"
                        maxlength="32000"
                      />
                      <span
                        >&#8251;
                        {{ $t('support.prediction.popUp.input-guide') }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>{{ outputVar }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="textarea"
                        :label="outputVar"
                        v-model="popup.submitItem.outptVriabl"
                        name="outptVriabl"
                        :rules="{ required: true, max: 32000 }"
                        inputClass="input-24 w-100"
                        inputStyle="height:60px; overflow-y:auto;"
                        maxlength="32000"
                      />
                      <span
                        >&#8251;
                        {{ $t('support.prediction.popUp.input-guide') }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>{{ modelFile }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        :label="modelFile"
                        v-model="popup.submitItem.fileNm"
                        name="fileNm"
                        :rules="{ required: true, regex: /\S*\.zip/i }"
                        :errorData="zipErr"
                        inputClass="input-24"
                        inputStyle="width:250px"
                        :readonly="true"
                      />
                      <input
                        type="file"
                        ref="multi"
                        style="display:none"
                        @change="changeFile"
                      />
                      <input
                        type="button"
                        class="bt-line24"
                        :value="btnSelect"
                        @click="$refs.multi.click()"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ scriptFile }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        :label="scriptFile"
                        v-model="popup.submitItem.executScript"
                        name="executScript"
                        :rules="{ required: true, max: 200 }"
                        inputClass="input-24 w-100"
                        maxlength="200"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </validation-observer>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="popup.type == 'insert' ? btnRegister : btnSubmit"
              class="bt-line30 cr-orange"
              @click="popup.type == 'insert' ? insertSubmit() : updateSubmit()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup"
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import ValidateForm from '@/components/ValidateForm.vue';

import mixins from '@/mixins/CommonMixins';
import {
  getPredictionList,
  getPredictionDetail,
  insertModel,
  updateModel,
  deleteModel,
} from '@/api/supportApi';

export default {
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    ValidateForm,
  },
  data() {
    return {
      // --- 테이블 --- //
      loading: false,
      // 검색 정보
      searchOpts: {
        predictModlId: '',
        predictModlNm: '',
      },
      // row
      totalRecords: 0,
      totalPages: 0,
      tableItems: [],
      nowPerPage: 30,
      reloadToggle: true,
      // 우측 패널 테이블
      tabOverlay: false,
      tabItems: [],
      // -- 팝업 -- //
      popup: {
        type: '',
        show: false,
        submitItem: {
          predictModlNm: '',
          predictModlDc: '',
          predictModlTy: '',
          predictModlVer: '',
          inputVriabl: '',
          outptVriabl: '',
          executScript: '',
          multi: '',
          fileNm: '',
        },
      },
    };
  },
  created() {
    this.tabItems = this.tabItemsComputed;
  },
  computed: {
    // 선택한 row의 predictModlId
    selected_predictModlId() {
      return this.tabItems[0].selectedItem.predictModlId.value;
    },
    itemsWithCustom() {
      return this.tableItems.map((tableItems, index) => ({
        ...tableItems,
        registDt: this.formatDate(
          new Date(this.tableItems[index].registDt),
          'yyyy/MM/dd hh:mm:ss',
        ),
        updtDt: this.formatDate(
          new Date(this.tableItems[index].updtDt),
          'yyyy/MM/dd hh:mm:ss',
        ),
      }));
    },
    leftPanelTitle() {
      return this.$i18n.t('support.prediction.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('support.prediction.leftPanel.table-column1'),
          value: 'predictModlId',
          width: '15%',
        },
        {
          text: this.$i18n.t('support.prediction.leftPanel.table-column2'),
          value: 'predictModlNm',
          width: '27%',
        },
        {
          text: this.$i18n.t('support.prediction.leftPanel.table-column3'),
          sortable: false,
          value: 'predictModlTy',
          width: '14%',
        },
        {
          text: this.$i18n.t('support.prediction.leftPanel.table-column4'),
          sortable: false,
          value: 'predictModlVer',
          width: '14%',
        },
        {
          text: this.$i18n.t('support.prediction.leftPanel.table-column5'),
          value: 'registDt',
          width: '15%',
        },
        {
          text: this.$i18n.t('support.prediction.leftPanel.table-column6'),
          sortable: false,
          value: 'updtDt',
          width: '15%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('support.prediction.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'detail',
          headers: [
            {
              text: this.$i18n.t('support.prediction.rightPanel.header1'),
              width: '40%',
            },
            {
              text: this.$i18n.t('support.prediction.rightPanel.header2'),
              width: '60%',
            },
          ],
          selectedItem: {
            predictModlId: {
              name: this.$i18n.t('support.prediction.rightPanel.label1'),
              value: '',
            },
            predictModlNm: {
              name: this.$i18n.t('support.prediction.rightPanel.label2'),
              value: '',
            },
            predictModlTy: {
              name: this.$i18n.t('support.prediction.rightPanel.label3'),
              value: '',
            },
            predictModlVer: {
              name: this.$i18n.t('support.prediction.rightPanel.label4'),
              value: '',
            },
            predictModlDc: {
              name: this.$i18n.t('support.prediction.rightPanel.label5'),
              value: '',
              isCustom: true,
            },
            inputVriabl: {
              name: this.$i18n.t('support.prediction.rightPanel.label6'),
              value: '',
              isCustom: true,
            },
            outptVriabl: {
              name: this.$i18n.t('support.prediction.rightPanel.label7'),
              value: '',
              isCustom: true,
            },
            fileNm: {
              name: this.$i18n.t('support.prediction.rightPanel.label8'),
              value: '',
              isCustom: true,
            },
            executScript: {
              name: this.$i18n.t('support.prediction.rightPanel.label9'),
              value: '',
            },
            registerId: {
              name: this.$i18n.t('support.prediction.rightPanel.label10'),
              value: '',
            },
            registDt: {
              name: this.$i18n.t('support.prediction.rightPanel.label11'),
              value: '',
            },
            updusrId: {
              name: this.$i18n.t('support.prediction.rightPanel.label12'),
              value: '',
            },
            updtDt: {
              name: this.$i18n.t('support.prediction.rightPanel.label13'),
              value: '',
            },
          },
        },
      ];
    },
    modelName() {
      return this.$i18n.t('support.prediction.popUp.label1');
    },
    modelType() {
      return this.$i18n.t('support.prediction.popUp.label2');
    },
    modelVer() {
      return this.$i18n.t('support.prediction.popUp.label3');
    },
    modelDesc() {
      return this.$i18n.t('support.prediction.popUp.label4');
    },
    inputVar() {
      return this.$i18n.t('support.prediction.popUp.label5');
    },
    outputVar() {
      return this.$i18n.t('support.prediction.popUp.label6');
    },
    modelFile() {
      return this.$i18n.t('support.prediction.popUp.label7');
    },
    scriptFile() {
      return this.$i18n.t('support.prediction.popUp.label8');
    },
    zipErr() {
      return {
        regex: this.$i18n.t('common.validate.required-zip'),
      };
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    btnSelect() {
      return this.$i18n.t('common.btn.select');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
    },
  },
  methods: {
    // 예측 모델 목록 조회
    async searchPrdcList(searchParams) {
      this.loading = true;
      const {
        rows,
        page,
        sord,
        sidx,
        predictModlId,
        predictModlNm,
      } = searchParams;

      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        predictModlId: predictModlId.trim(),
        predictModlNm: predictModlNm.trim(),
      };
      await getPredictionList(params).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.tableItems = rows;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
        this.loading = false;
      });
    },
    // row 클릭 시, 상세 조회
    async rowClick(item) {
      this.tabOverlay = true;
      await getPredictionDetail(item.predictModlId).then(({ data }) => {
        Object.keys(this.tabItems[0].selectedItem).forEach(key => {
          if (key == 'registDt' || key == 'updtDt') {
            this.tabItems[0].selectedItem[key].value = data[key]
              ? this.formatDate(new Date(data[key]), 'yyyy/MM/dd hh:mm:ss')
              : '';
          } else {
            this.tabItems[0].selectedItem[key].value = data[key];
          }
        });
      });
      this.tabOverlay = false;
    },
    // 모델 등록
    async insertSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      await insertModel(this.popup.submitItem).then(res => {
        const { status, data } = res;
        if (status == 200) {
          this.$dialogs
            .alert(this.$i18n.t('common.alert.insert-success'))
            .then(() => {
              this.closePopup();
              this.clearTabItems();
              this.reloadToggle = !this.reloadToggle;
            });
        } else {
          this.$dialogs.alert(
            data.message
              ? data.message
              : this.$i18n.t('common.alert.insert-fail'),
          );
        }
      });
    },
    // 모델 수정
    async updateSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      const predictModlId = this.selected_predictModlId;
      await updateModel(predictModlId, this.popup.submitItem).then(res => {
        const { status, data } = res;
        if (status == 200) {
          this.$dialogs
            .alert(this.$i18n.t('common.alert.update-success'))
            .then(() => {
              this.closePopup();
              // 표 reload
              this.reloadToggle = !this.reloadToggle;
              // 수정한 모델 재선택
              this.rowClick({ predictModlId });
            });
        } else {
          this.$dialogs.alert(
            data.message
              ? data.message
              : this.$i18n.t('common.alert.update-fail'),
          );
        }
      });
    },
    // 모델 삭제
    async deleteModel() {
      if (!this.selected_predictModlId) {
        const target = this.getPostWord(
          this.$i18n.t('support.prediction.target-model'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }

      await deleteModel(this.selected_predictModlId).then(() => {
        this.$dialogs
          .alert(this.$i18n.t('common.alert.delete-success'))
          .then(() => {
            this.closePopup();
            //  선택 정보 clear
            this.clearTabItems();
            // 표 reload
            this.reloadToggle = !this.reloadToggle;
          });
      });
    },
    // 파일 다운로드
    fileDownload() {
      window.location.href = `${process.env.VUE_APP_URL_MAS_API}/support/prediction/file/${this.selected_predictModlId}?token=${this.$store.state.auth.token}`;
    },
    // 파일 찾기 완료 시..
    changeFile() {
      this.popup.submitItem.multi = this.$refs.multi.files[0];
      this.popup.submitItem.fileNm = this.$refs.multi.files[0].name;
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    openPopup(type) {
      this.popup.type = type;
      if (type == 'insert') {
        this.popup.title = this.$i18n.t(
          'support.prediction.popUp.title-insert',
        );
      } else {
        this.popup.title = this.$i18n.t(
          'support.prediction.popUp.title-update',
        );
        // 선택한 row
        const selectedItem = this.tabItems[0].selectedItem;
        if (!this.selected_predictModlId) {
          const target = this.getPostWord(
            this.$i18n.t('support.prediction.target-model'),
            '을',
            '를',
          );
          this.$dialogs.alert(
            this.$i18n.t('common.alert.target-update', { target }),
          );
          return;
        }
        Object.keys(this.popup.submitItem).forEach(key => {
          this.popup.submitItem[key] = selectedItem[key]?.value;
        });
      }
      this.popup.show = true;
    },
    closePopup() {
      this.popup.show = false;
      Object.keys(this.popup.submitItem).forEach(key => {
        this.popup.submitItem[key] = '';
      });
    },
    // 우측 패널 데이터 초기화
    clearTabItems() {
      //  선택 정보 clear
      Object.keys(this.tabItems[0].selectedItem).forEach(key => {
        this.tabItems[0].selectedItem[key].value = '';
      });
    },
  },
};
</script>
<style scoped></style>
