<template>
  <div>
    <four-panel
      :topLeftPanelWidth="'820'"
      :topRightPanelWidth="'567'"
      :bottomLeftPanelWidth="'820'"
      :bottomRightPanelWidth="'567'"
      :topLeftPanelTitle="topLeftPanelTitle"
      :topRightPanelTitle="topRightPanelTitle"
      :bottomLeftPanelTitle="bottomLeftPanelTitle"
      :bottomRightPanelTitle="bottomRightPanelTitle"
    >
      <template #topLeftPanel>
        <table-list-component
          ref="userStatusTableListComponent"
          :tableHeight="'231'"
          :headers="userStatus.headers"
          :items="userStatus.list"
          :nowPerPage="userStatus.nowPerPage"
          :totalRecords="userStatus.totalRecords"
          :totalPages="userStatus.totalPages"
          :customSlotInfo="userStatus.customSlotInfo"
          :searchOpts="userStatus.search"
          :loading="userStatus.isTableOverlay"
          @rowClick="userStatusRowClick"
          @getDataFromApi="doGetUserStatusList"
          :showFirstLastIcon="false"
        >
          <template #searchOptions>
            <label class="sort-label"
              >· {{ $t('support.transaction.search-condition1') }}</label
            >
            <input
              type="text"
              class="sort-input-text"
              v-model="userStatus.search.userId"
              maxlength="20"
              @keyup.enter="doSearch()"
              @keydown="inputLiteralCheck"
            />
          </template>

          <template v-slot:srbdeDt_custom="{ item }">
            <td class="text-center">
              {{ item.srbdeDt !== '-' ? getFormatDate(item.srbdeDt) : '-' }}
            </td>
          </template>
        </table-list-component>
      </template>

      <template #topRightPanel>
        <table-tab-component
          :tabItems="userStatus.tabItems"
          :tableHeight="'338'"
          :tabOverlay="userStatus.isTabOverlay"
        >
          <template v-slot:srbdeDt_tab_custom="{ item }">
            <td>
              {{
                item.value === 0 || item.value === '-'
                  ? '-'
                  : getFormatDate(item.value)
              }}
            </td>
          </template>

          <template v-slot:patient_tab_custom="{ item }">
            <td>
              <div style="width: 444px; height:198px; overflow-y:auto;">
                <pre
                  style="white-space: pre-wrap;"
                  v-html="
                    !isEmptyObject(item.value)
                      ? getPatientText(item.value)
                      : '-'
                  "
                ></pre>
              </div>
            </td>
          </template>

          <template #userStatusDetail_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnShowHist"
                  class="bt-line24 cr-gray"
                  @click="doGetTransactionHistoryList()"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>

      <template #bottomLeftPanel>
        <v-data-table
          class="elevation-1"
          dense
          hide-default-footer
          :fixed-header="true"
          :height="'338'"
          :headers="transaction.headers"
          :items="transaction.list"
          item-key="txId"
          :single-select="true"
          :loading="transaction.isTableOverlay"
          :disable-pagination="true"
          @click:row="transactionRowClick"
        >
          <template v-slot:no-data>
            {{ $t('support.transaction.bottomLeftPanel.desc') }}
          </template>

          <template v-slot:[`item.txId`]="{ item }">
            <td class="text-center">{{ item.txId || '-' }}</td>
          </template>

          <template v-slot:[`item.timestamp`]="{ item }">
            <td class="text-center">
              {{
                !item.timestamp || item.timestamp === 0
                  ? '-'
                  : getFormatDate(item.timestamp)
              }}
            </td>
          </template>

          <template v-slot:progress>
            <v-progress-linear
              :active="transaction.isTableOverlay"
              :indeterminate="transaction.isTableOverlay"
              absolute
              bottom
              color="deep-orange lighten-2"
            ></v-progress-linear>
          </template>
        </v-data-table>
      </template>

      <template #bottomRightPanel>
        <table-tab-component
          :tabItems="transaction.tabItems"
          :tableHeight="'338'"
          :tabOverlay="transaction.isTabOverlay"
        >
          <template v-slot:timestamp_tab_custom="{ item }">
            <td>
              {{
                !item.value || item.value === 0
                  ? '-'
                  : getFormatDate(item.value)
              }}
            </td>
          </template>

          <template v-slot:value_tab_custom="{ item }">
            <td>
              <div style="width: 444px; height:255px; overflow-y:auto;">
                <pre
                  style="white-space: pre-wrap;"
                  v-html="
                    !isEmptyObject(item.value)
                      ? getPatientText(item.value)
                      : '-'
                  "
                ></pre>
              </div>
            </td>
          </template>
        </table-tab-component>
      </template>
    </four-panel>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import FourPanel from '@/layouts/FourPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import TableTabComponent from '@/components/TableTabComponent.vue';
import { getUserStatusList, getTransactionHistoryList } from '@/api/supportApi';

export default {
  data() {
    return {};
  },
  mixins: [CommonMixins],
  components: {
    FourPanel,
    TableListComponent,
    TableTabComponent,
  },
  computed: {
    topLeftPanelTitle() {
      return this.$i18n.t('support.transaction.topLeftPanel.title');
    },
    topRightPanelTitle() {
      return this.$i18n.t('support.transaction.topRightPanel.title');
    },
    bottomLeftPanelTitle() {
      return this.$i18n.t('support.transaction.bottomLeftPanel.title');
    },
    bottomRightPanelTitle() {
      return this.$i18n.t('support.transaction.bottomRightPanel.title');
    },
    userStatus() {
      return {
        headers: [
          {
            text: this.$i18n.t(
              'support.transaction.topLeftPanel.table-column1',
            ),
            value: 'userId',
            width: '27%',
            sortable: false,
          },
          {
            text: this.$i18n.t(
              'support.transaction.topLeftPanel.table-column2',
            ),
            value: 'username',
            width: '23%',
            sortable: false,
          },
          {
            text: this.$i18n.t(
              'support.transaction.topLeftPanel.table-column3',
            ),
            value: 'srbdeDt',
            width: '40%',
            sortable: false,
          },
        ],
        list: [],
        nowPerPage: 10,
        totalRecords: 0,
        totalPages: 0,
        isTableOverlay: false,
        customSlotInfo: [
          {
            name: 'srbdeDt',
            slotName: 'item.srbdeDt',
          },
        ],
        search: {
          userId: '',
        },
        tabItems: [
          {
            id: 'userStatusDetail',
            headers: [
              {
                text: this.$i18n.t('support.transaction.topRightPanel.header1'),
                width: '20%',
              },
              {
                text: this.$i18n.t('support.transaction.topRightPanel.header2'),
                width: '80%',
              },
            ],
            isBottomBtn: true,
            selectedItem: {
              userId: {
                name: this.$i18n.t('support.transaction.topRightPanel.label1'),
                value: '',
              },
              username: {
                name: this.$i18n.t('support.transaction.topRightPanel.label2'),
                value: '',
              },
              srbdeDt: {
                name: this.$i18n.t('support.transaction.topRightPanel.label3'),
                value: 0,
                isCustom: true,
              },
              patient: {
                name: this.$i18n.t('support.transaction.topRightPanel.label4'),
                value: {},
                isCustom: true,
              },
            },
          },
        ],
        bookmarkMap: {},
        isTabOverlay: false,
        clickedRow: {},
      };
    },
    transaction() {
      return {
        headers: [
          {
            text: this.$i18n.t(
              'support.transaction.bottomLeftPanel.table-column1',
            ),
            value: 'txId',
            width: '70%',
            sortable: false,
          },
          {
            text: this.$i18n.t(
              'support.transaction.bottomLeftPanel.table-column2',
            ),
            value: 'timestamp',
            width: '30%',
            sortable: false,
          },
        ],
        list: [],
        isTableOverlay: false,
        tabItems: [
          {
            id: 'transactionDetail',
            headers: [
              {
                text: this.$i18n.t(
                  'support.transaction.bottomRightPanel.header1',
                ),
                width: '20%',
              },
              {
                text: this.$i18n.t(
                  'support.transaction.bottomRightPanel.header2',
                ),
                width: '80%',
              },
            ],
            isBottomBtn: false,
            selectedItem: {
              txId: {
                name: this.$i18n.t(
                  'support.transaction.bottomRightPanel.label1',
                ),
                value: '',
              },
              timestamp: {
                name: this.$i18n.t(
                  'support.transaction.bottomRightPanel.label2',
                ),
                value: 0,
                isCustom: true,
              },
              value: {
                name: this.$i18n.t(
                  'support.transaction.bottomRightPanel.label3',
                ),
                value: {},
                isCustom: true,
              },
            },
          },
        ],
        isTabOverlay: false,
        clickedRow: {},
      };
    },
    btnShowHist() {
      return this.$i18n.t('common.btn.show-history');
    },
  },
  methods: {
    doResetClicked() {
      this.userStatus.clickedRow = {};
      this.doResetUserStatusTab();
      this.doResetTransaction();
    },
    async doGetUserStatusList(searchParams) {
      try {
        this.userStatus.isTableOverlay = true;
        this.doResetClicked();
        const { rows, page, sord, sidx, userId } = searchParams;

        const reqObj = {
          page,
          rows,
          sidx,
          sord,
          userId: userId.trim(),
        };

        if (!this.isEmptyObject(this.userStatus.bookmarkMap)) {
          Object.assign(reqObj, {
            bookmark: this.userStatus.bookmarkMap[page - 1],
          });
        }

        const response = await getUserStatusList(reqObj);
        const { rows: items, totalRecords, totalPages, pageId } = response.data;
        this.userStatus.list = items;
        this.userStatus.totalRecords = totalRecords;
        this.userStatus.totalPages = totalPages;
        this.userStatus.bookmarkMap[page] = pageId;
      } catch (error) {
        console.log('error', error);
        if (error.response.status === 500) {
          this.$dialogs.alert(error.response.data.message);
        }
      } finally {
        this.userStatus.isTableOverlay = false;
      }
    },
    userStatusRowClick(item) {
      this.doResetTransaction();
      this.userStatus.isTabOverlay = true;
      this.userStatus.clickedRow = item;
      const userStatusDetailTab = this.userStatus.tabItems[0];
      let keys = Object.keys(userStatusDetailTab.selectedItem);
      keys.forEach(key => {
        userStatusDetailTab.selectedItem[
          key
        ].value = this.userStatus.clickedRow[key];
      });
      this.userStatus.isTabOverlay = false;
    },
    doSearch() {
      this.$refs.userStatusTableListComponent.search();
    },
    getPatientText(patientObj) {
      if (patientObj.resources) {
        patientObj.resources.forEach(resource => {
          if (resource.lastUpdated) {
            resource.lastUpdated = this.getFormatDate(resource.lastUpdated);
          }
        });
      }
      return JSON.stringify(patientObj, 2, 2);
    },
    doResetUserStatusTab() {
      const userStatusDetailTab = this.userStatus.tabItems[0];
      let keys = Object.keys(userStatusDetailTab.selectedItem);
      keys.forEach(key => {
        if (key === 'patient') {
          userStatusDetailTab.selectedItem[key].value = {};
        } else if (key === 'srbdeDt') {
          userStatusDetailTab.selectedItem[key].value = 0;
        } else {
          userStatusDetailTab.selectedItem[key].value = '';
        }
      });
    },
    async doGetTransactionHistoryList() {
      if (this.isEmptyObject(this.userStatus.clickedRow)) {
        const target = this.getPostWord(
          this.$i18n.t('support.transaction.target-user'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }

      const { userId } = this.userStatus.clickedRow;

      try {
        this.transaction.isTableOverlay = true;

        const reqObj = {
          userId,
        };

        const response = await getTransactionHistoryList(reqObj);
        this.transaction.list = response.data;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.transaction.isTableOverlay = false;
      }
    },
    transactionRowClick(item, row) {
      if (row.isSelected) {
        row.select(false);
      } else {
        row.select(true);
      }
      this.transaction.isTabOverlay = true;
      this.transaction.clickedRow = item;
      const transactionDetailTab = this.transaction.tabItems[0];
      let keys = Object.keys(transactionDetailTab.selectedItem);
      keys.forEach(key => {
        transactionDetailTab.selectedItem[
          key
        ].value = this.transaction.clickedRow[key];
      });
      this.transaction.isTabOverlay = false;
    },
    doResetTransactionTab() {
      const transactionDetailTab = this.transaction.tabItems[0];
      let keys = Object.keys(transactionDetailTab.selectedItem);
      keys.forEach(key => {
        if (key === 'timestamp') {
          transactionDetailTab.selectedItem[key].value = 0;
        } else if (key === 'value') {
          transactionDetailTab.selectedItem[key].value = {};
        } else {
          transactionDetailTab.selectedItem[key].value = '';
        }
      });
    },
    doResetTransaction() {
      this.transaction.list = [];
      this.transaction.clickedRow = {};
      this.doResetTransactionTab();
    },
  },
};
</script>

<style scoped></style>
